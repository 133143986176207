import '../../node_modules/bootstrap/js/dist/button';
import '../../node_modules/bootstrap/js/dist/offcanvas';

document.addEventListener('DOMContentLoaded', function() {
  const errors = new Array();

  function checkErrors() {
    if (errors.length !== 0) {
      return false;
    }

    return true;
  }

  function validForm(form) {
    // get all inputs
    const name = form.contactName;
    const email = form.contactEmail;
    const phone = form.contactPhone;
    const subject = form.contactSubject
    const message = form.contactMessage;
    const formInputs = [name, email, phone, subject, message];

    const regexName = /^([A-Za-z])+$/;
    const regexEmail = /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
    const regexPhone = /^([0-9])+$/;
    const regexSubject = /^([A-Za-z.-?])$/;

    errors.length = 0;

    formInputs.forEach(function(input) {
      if (input.getAttribute('name') !== 'phone') {
        input.setAttribute('required', '');
      }

      switch (input.getAttribute('name')) {
      case 'name':
        if (!regexName.test(input.value) && input.value.length < 3) {
          input.classList.add('is-invalid');

          errors.push(input.getAttribute('name'));
        } else {
          if (input.classList.contains('is-invalid')) {
            input.classList.remove('is-invalid');
          }

          input.classList.add('is-valid');
        }
        break;

      case 'email':
        if (!regexEmail.test(input.value)) {
          input.classList.add('is-invalid');

          errors.push(input.getAttribute('name'));
        } else {
          if (input.classList.contains('is-invalid')) {
            input.classList.remove('is-invalid');
          }

          input.classList.add('is-valid');
        }
        break;

      case 'phone':
        if (input.value !== '' && input.value.length > 0) {
          if (!regexPhone.test(input.value) || input.value.length > 14) {
            input.classList.add('is-invalid');

            errors.push(input.getAttribute('name'));
          } else {
            if (input.classList.contains('is-invalid')) {
              input.classList.remove('is-invalid');
            }

            input.classList.add('is-valid');
          }
        } else {
          if (input.classList.contains('is-invalid') &&
            input.value.length === 0) {
            input.classList.remove('is-invalid');
          }

          if (input.classList.contains('is-valid') &&
              input.value.length === 0) {
            input.classList.remove('is-valid');
          }
        }
        break;

      case 'subject':
        if (!regexSubject.test(input.value) && input.value.length < 6) {
          input.classList.add('is-invalid');

          errors.push(input.getAttribute('name'));
        } else {
          if (input.classList.contains('is-invalid')) {
            input.classList.remove('is-invalid');
          }

          input.classList.add('is-valid');
        }
        break;

      case 'message':
        if (input.value === '' || input.value.length < 10) {
          input.classList.add('is-invalid');

          errors.push(input.getAttribute('name'));
        } else {
          if (input.classList.contains('is-invalid')) {
            input.classList.remove('is-invalid');
          }

          input.classList.add('is-valid');
        }
        break;

      default:
        break;
      }
    });

    return checkErrors();
  }

  const forms = document.forms;

  if (forms.length == 0) {
    return
  } else {
    // get the form object
    const contactForm = forms.contactForm;
    // disable native browser validation
    contactForm.setAttribute('novalidate', '');
    // add csrf_token to formData if one exists
    if (contactForm.hasAttribute('csrf_token')) {
      FormData.append('csrf_token', contactForm.getAttribute('value'));
    }
    // validate inputs on form submit
    contactForm.addEventListener('submit', function(e) {
      if (!validForm(contactForm)) {
        console.log('::Not valid form::');
        e.preventDefault();
      } else {
        console.log('::Valid form::');
        // contactForm.submit();
      }
    });
  }
});
